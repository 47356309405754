* {
  user-select: none;
}
*::selection {
  background: none;
}
*::-moz-selection {
  background: none;
}


h1 {
  font-size: calc(3em + 1vw);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.2;
  font-family: var(--heading-font);
}

h2 {
  font-size: calc(2em + 1vw);
  text-transform: capitalize;
  font-weight: 500;
  color: var(--main-color);
  line-height: 1.2;
  font-family: var(--heading-font);
}

h3 {
  font-size: calc(1.8em + 1vw);
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--heading-font);
}

h4 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--heading-font);
}

h5 {
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--heading-font);
}

h6 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: var(--heading-font);
}

p {
  line-height: 35px;
}

.gallery-content {
  columns: 5 320px;
}

.gallery-item {
  columns: 5 320px;
}

.gallery-content img {
  margin: 10px;
}

.pswp__img {
  object-fit: cover !important;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: calc(2em + 1vw);
  }

  h4 {
    font-size: calc(1em + 1vw);
  }

}

.content-directorios {
  align-self: center;
}

.bgblockAboutbgImage{
  position: relative;
}
.bgblockAboutbgImage::before{
  content: '';
  position: absolute;
  top: 50px;
  background-image: url('../css/casaplano.png');
  background-size: 50%;
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/*estilo del contador de info */
.bgCountent{
  position: relative;
  padding: 150px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bgCountent::before{
  content: '';
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}